import React, { Component } from 'react'

export default class GoogleAdsenseAd extends Component {

    componentDidMount() {
        try {
            window.adsbygoogle = window.adsbygoogle || []
            window.adsbygoogle.push({})
        } catch (error) {
            console.log('All ad units are already populated')
        }
    }

    render = () => (
        <div className={this.props.className}>
            { this.props.children }
        </div>
    )
}