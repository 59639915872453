import './float-in.css';

let paused = false;
let listening = false;

window.addEventListener('load', () => {
	addListeners();
})

window.addEventListener('routeChange', () => {
	addListeners();
	floatInElements();
});

const addListeners = () => {
	if (listening)
		return;

	window.addEventListener("scroll", updateFloat);
	window.addEventListener("resize", updateFloat);
	listening = true;
}

const removeListeners = () => {
	window.removeEventListener("scroll", updateFloat);
	window.removeEventListener("resize", updateFloat);
	listening = false;
}

const updateFloat = () => {
	if (!paused)
		return;

	floatInElements();
}

const isAboveView = (element) =>
	element != null && window.pageYOffset > 100 && element.getBoundingClientRect().top < window.innerHeight / 3;

const isInView = (element) => {
	const top = element.getBoundingClientRect().top
	const windowBottom = window.innerHeight + window.pageYOffset;
	return element != null && top < windowBottom;
}

export const floatInElements = (force) => floatIn([...document.querySelectorAll('.float-in').values()], force);

const floatIn = (pending, force) => {
	if (pending.length === 0) {
		removeListeners();
		return;
	}

	if (!force && !isInView(pending[0])) {
		paused = true;
		return;
	}
	
	let element = pending.shift();
	
	// Set paused to false as animations are running
	paused = false;
	element.style.marginTop = "-5px";
	
	let delay = isAboveView(element) ? 0 : 250;
	
	setTimeout(() => {
		element.classList.add("float-in-animating");
		element.style.marginTop = null;
	}, delay);
	
	setTimeout(() => {
		element.classList.remove("float-in");
		element.classList.remove("float-in-animating");
		floatIn(pending, force);
	}, delay * 2);
}