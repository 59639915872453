import * as Browser from '../browser';

// Wait until the window is loaded before attempting to find any elements
window.addEventListener('load', function() {
	
	const elements = document.querySelectorAll("a[href^='#']");
	
	elements.forEach(element => {
		// Register an event to handle the element click
		element.addEventListener('click', onAnchorClick);
	});
	
	// Remove the listener after the load has occurred
	window.removeEventListener("load", this);
});

function getIdFromAnchor(href) {
	return href.substring(href.lastIndexOf("#") + 1);
}

function onAnchorClick(event) {
	
	const id = getIdFromAnchor(event.target.href);
	const element = document.getElementById(id);
	
	if (element == null) {
		return;
	}
	
	event.preventDefault();
	Browser.scrollToElement(element, 500, 75);
}