import React, { Component } from 'react';
import * as Util from '../../scripts/utilities/number';

export const BADGE_LEFT = 'LEFT';
export const BADGE_RIGHT = 'RIGHT';

class Badge extends Component {

    constructor(props) {
        super(props);
        this.state = {
            x: Util.toNumber(props.x),
            y: Util.toNumber(props.y),
            position: (props.position ? props.position : BADGE_LEFT)
        };
    }

    componentDidMount(props) {
    	const state = this.state;
    	state.x = (props && props.x ? Util.toNumber(props.x) : state.x);
        state.y = (props && props.y ? Util.toNumber(props.y) : state.y);
        state.position = (props && props.position ? props.position : state.position);
        state.style = this._getStyle(state.position, state.x, state.y);
        this.setState(state);
    }

    _getStyle = (position, x, y) => {
        switch (position) {

        case BADGE_LEFT:
            return {
                left: x + 'px',
                top: y + 'px'
            };

        case BADGE_RIGHT:
            return {
                right: x + 'px',
                top: y + 'px'
            };

        default:
            return {};
        }
    }

    render = () => {   
        return(
            <div className="pos-relative">
            	<div className="pos-absolute" style={this.state.style}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default Badge;