import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Redirect } from 'react-router-dom';
import Helmet from 'react-helmet';
import Row from '../../shared/components/layout/row';
import Card from '../../shared/components/card/card';
import Request from '../../shared/classes/communication/request';
import Badge, { BADGE_RIGHT } from '../../shared/components/badge/badge';
import { useFloatInTimeout } from '../../shared/hooks/use-float-in-timeout';
import LoadingOverlay from '../../shared/components/loading-overlay/loading-overlay';
import { floatInElements } from '../../shared/scripts/animations/float-in/float-in';

import './store.css';

const PLUGIN_BODY_MAPPING = {
    procheckout: {
        showLogo: true,
        render: (description) => (
            <div className="mx-md">
                {description}
                <p>
                    <a href="https://pay.culleystudios.com/buy/procheckout" className="m-sm">
                        <img src="https://culleystudios.com/images/buttons/buy-button.png" alt="Buy now!" />
                    </a>
                    <a href="https://link.culleystudios.com/cs-procheckout-spigot" className="m-sm">
                        <img src="https://culleystudios.com/images/buttons/download-button.png" alt="Download" />
                    </a>
                </p>
            </div>
        ),
    },
    minegpt: {
        showLogo: true,
        render: (description) => (
            <div className="mx-md">
                {description}
                <p>
                    <a href="https://pay.culleystudios.com/buy/minegpt" className="m-sm">
                        <img src="https://culleystudios.com/images/buttons/buy-button.png" alt="Buy now!" />
                    </a>
                    <a href="https://link.culleystudios.com/cs-minegpt-spigot" className="m-sm">
                        <img src="https://culleystudios.com/images/buttons/download-button.png" alt="Download" />
                    </a>
                </p>
            </div>
        ),
    },
    promenus: {
        showLogo: true,
        render: (description) => (
            <div className="mx-md">
                {description}
                <p>
                    <a href="https://pay.culleystudios.com/buy/promenus" className="m-sm">
                        <img src="https://culleystudios.com/images/buttons/buy-button.png" alt="Buy now!" />
                    </a>
                    <a href="https://link.culleystudios.com/cs-promenus-spigot" className="m-sm">
                        <img src="https://culleystudios.com/images/buttons/download-button.png" alt="Download" />
                    </a>
                </p>
            </div>
        ),
    },
    dailybonus: {
        showLogo: true,
        render: (description) => (
            <div className="mx-md">
                {description}
                <p>
                    <a href="https://pay.culleystudios.com/buy/dailybonus" className="m-sm">
                        <img src="https://culleystudios.com/images/buttons/buy-button.png" alt="Buy now!" />
                    </a>
                    <a href="https://link.culleystudios.com/cs-dailybonus-spigot" className="m-sm">
                        <img src="https://culleystudios.com/images/buttons/download-button.png" alt="Download" />
                    </a>
                </p>
            </div>
        )
    },
    'dailybonus-scheduler': {
        showLogo: true,
        render: (description) => (
            <div className="mx-md">
                {description}
                <p>
                    <a href="https://pay.culleystudios.com/buy/dailybonus-scheduler" className="m-sm">
                        <img src="https://culleystudios.com/images/buttons/buy-button.png" alt="Buy now!" />
                    </a>
                    <a href="https://link.culleystudios.com/cs-dailybonus-scheduler-spigot" className="m-sm">
                        <img src="https://culleystudios.com/images/buttons/download-button.png" alt="Download" />
                    </a>
                </p>
            </div>
        )
    },
    projackpots: {
        showLogo: true,
        render: (description) => (
            <div className="mx-md">
                {description}
                <p>
                    <a href="https://pay.culleystudios.com/buy/projackpots" className="m-sm">
                        <img src="https://culleystudios.com/images/buttons/buy-button.png" alt="Buy now!" />
                    </a>
                    <a href="https://link.culleystudios.com/cs-projackpots-spigot" className="m-sm">
                        <img src="https://culleystudios.com/images/buttons/download-button.png" alt="Download" />
                    </a>
                </p>
            </div>
        )
    },
    provotes: {
        render: (description) => (
            <div className="mx-md">
                {description}
                <p>
                    <a href="https://pay.culleystudios.com/buy/provotes" className="m-sm">
                        <img src="https://culleystudios.com/images/buttons/buy-button.png" alt="Buy now!" />
                    </a>
                    <a href="https://link.culleystudios.com/cs-provotes-spigot" className="m-sm">
                        <img src="https://culleystudios.com/images/buttons/download-button.png" alt="Download" />
                    </a>
                </p>
            </div>
        )
    },
    propackages: {
        render: (description) => (
            <div className="mx-md">
                {description}
                <p>
                    <a href="https://pay.culleystudios.com/buy/propackages" className="m-sm">
                        <img src="https://culleystudios.com/images/buttons/buy-button.png" alt="Buy now!" />
                    </a>
                    <a href="https://link.culleystudios.com/cs-propackages-spigot" className="m-sm">
                        <img src="https://culleystudios.com/images/buttons/download-button.png" alt="Download" />
                    </a>
                </p>
            </div>
        )
    },
    proquests: {
        render: (description) => (
            <div className="mx-md">
                {description}
                <p>
                    <a href="https://pay.culleystudios.com/buy/proquests" className="m-sm">
                        <img src="https://culleystudios.com/images/buttons/buy-button.png" alt="Buy now!" />
                    </a>
                    <a href="https://link.culleystudios.com/cs-proquests-spigot" className="m-sm">
                        <img src="https://culleystudios.com/images/buttons/download-button.png" alt="Download" />
                    </a>
                </p>
            </div>
        )
    },
}

const StoreProduct = ({ productId, name, description, logoUrl, price, salePrice, saleEnd }) => {
    const date = new Date()
    const saleEndDate = new Date(saleEnd)

    const activeSale = salePrice !== price && date.getTime() < saleEndDate.getTime()

    const priceLabel = activeSale ? <span><b>${salePrice}</b> <s>${price}</s></span> :
        <span>${price}</span>

    const { showLogo, render } = PLUGIN_BODY_MAPPING[productId]

    const descriptionComponent = !description ? undefined :
        (<div>{description.split(/\n/).map(part => <p>{part}</p>)}</div>)

    return (
        <div className="w-4 w-mb-10 float-in">
            <Badge position={BADGE_RIGHT} x="-5" y="-10">
                <div className="secondary p-sm r-sm">
                    {priceLabel}
                </div>
            </Badge>
            <Card className="bg-secondary"
                headerClass="bg-tertiary"
                header={
                    showLogo && logoUrl ? <img src={logoUrl} alt={`${name} Logo`} /> :
                        <div>
                            <h2 className="mx-md">{name}</h2>
                            <hr className="primary w-10"/>
                        </div>
                }
                bodyClass="document-container"
                body={render(descriptionComponent)}>
            </Card>
        </div>
    )
}


const Store = () => {
    const loadingOverlay = useRef()
    const [started, setStarted] = useState(true)
    const [redirect, setRedirect] = useState(false)
    const [products, setProducts] = useState()

    const keys = useMemo(() => Object.keys(PLUGIN_BODY_MAPPING), [])

    useEffect(() => {
        if (!started) return
        
        const redirectHandler = (error) => {
            loadingOverlay.current.setAnimating(false);
            console.error(error);
            setRedirect(true);
        }

        loadingOverlay.current.setAnimating(true);
        setStarted(false);

        new Request(`https://api.culleystudios.com/api/v1/product?productType=PLUGIN`, 15000, (data) => {
            loadingOverlay.current.setAnimating(false);
            setProducts(JSON.parse(data.target.response))
            floatInElements(true)
        }, redirectHandler, redirectHandler).getJSON();
    }, [started])

    useFloatInTimeout(3500)
    
    return (
        <div>
            <Helmet>
                <title>Culley Studios | Store</title>
            </Helmet>
            {redirect === true && <Redirect to="/" />}
            <div className="mx-lg">
                <div className="float-in">
                    <h1 className="text-center">Spigot Plugins</h1>
                    <hr className="primary m-auto w-4 w-mb-8" />
                    <br/>
                </div>
                <br/>
                <Row className="flex-wrap w-10 gap-md">
                    {products?.length ? keys.map(key =>
                        <StoreProduct {...products.find(({ productId }) => productId === key)} />
                    ) : (
                        <div className="loading-content"/>
                    )}
                    <LoadingOverlay ref={loadingOverlay} delay="100" />
                </Row>
                <br/>
            </div>
        </div>
    );
}

export default Store;