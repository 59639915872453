import React, { Component } from 'react';

class SlideshowElement extends Component {

    constructor(props) {
        super(props);
        
        // Check to ensure an id is provided
        if (props.id === undefined) {
            throw new Error('All slideshow elements must have an id provided');
        }
    }

    render = () => {

        const className = (this.props.className ? ' ' + this.props.className : '');

        return(
            <div id={this.props.id} className={'pos-absolute' + className} style={this.props.style}>
            	{this.props.children}
            </div>
        );
    }
}

export default SlideshowElement;