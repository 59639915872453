const root = document.documentElement

export const loadTheme = () => {
    window.currentTheme = window.matchMedia("(prefers-color-scheme: dark)").matches ? 'dark' : 'light';
    setColorTheme(window.currentTheme);
}

export const toggleColorTheme = () => {
    switch (window.currentTheme) {

    case 'dark':
        setColorTheme();
        break;
        
    default: 
        setColorTheme('dark');
        break;
    }
}

export const setColorTheme = (style) => {
    switch (style) {

    case 'dark':
        root.style.setProperty('--font-color', '#ffffff');
        root.style.setProperty('--primary-color', '#0d82a2');
        root.style.setProperty('--secondary-color', '#6bc111');
        root.style.setProperty('--tertiary-color', '#fb8c00');
        root.style.setProperty('--accent-color', '#000000');
        root.style.setProperty('--primary-bg-color', '#0d0d0d');
        root.style.setProperty('--secondary-bg-color', '#171717');
        root.style.setProperty('--tertiary-bg-color', '#121212');
        window.currentTheme = 'dark';
        break;

    default:
        root.style.setProperty('--font-color', '#1c1c1c');
        root.style.setProperty('--primary-color', '#12b6e2');
        root.style.setProperty('--secondary-color', '#78d813');
        root.style.setProperty('--tertiary-color', '#ffa726');
        root.style.setProperty('--accent-color', '#fffff');
        root.style.setProperty('--primary-bg-color', '#f7f7f7');
        root.style.setProperty('--secondary-bg-color', '#ffffff');
        root.style.setProperty('--tertiary-bg-color', '#eeeeee');
        window.currentTheme = 'light';
        break;

    }
    
    // Call an event that the theme was changed
    window.dispatchEvent(new Event('theme'));
}

export const getSkeletonColor = () => {
    switch (window.currentTheme) {

    case 'dark':
        return '#121212'
        
    default: 
        return '#eeeeee'
    }
}

export const getSkeletonHighlightColor = () => {
    switch (window.currentTheme) {

    case 'dark':
        return '#171717'
        
    default: 
        return '#ffffff'
    }
}