import FormField from './form-field';

export default class FieldValidator {
	
	error: string;
	
	constructor(error: string) {
		this.error = error;
		this.validateField = this.validateField.bind(this);
	}

	validateField(field: FormField): boolean {
		return false;
	}
}