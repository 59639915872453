import React from 'react'
import Column from '../../shared/components/layout/column'
import Row from '../../shared/components/layout/row'
import Badge, { BADGE_RIGHT } from '../../shared/components/badge/badge';
import * as Theme from '../../shared/scripts/theme';
import ThemeImage from '../../shared/components/image/theme-image';
import Helmet from 'react-helmet';

const Jacob = () => (
    <div>
        <Helmet>
            <title>Culley Studios | Jacob Culley</title>
        </Helmet>
        <Row className="flex-a-items-start flex-mb-wrap mx-lg">
            <Column className="w-4 w-mb-9 mx-lg">
                <div className="w-10 r-sm my-lg bg-tertiary" style={{height: '800px'}}>
                    <Badge position={BADGE_RIGHT} x="-12" y="-12">
                        <button className="btn btn-primary r-circle" onClick={Theme.toggleColorTheme}>
                            <ThemeImage alt="Toggle theme" className="btn-img-icon" images={{ 
                                'dark': { 
                                    'src': '/images/icons/sun.svg',
                                },
                                'light': {
                                    'src': '/images/icons/moon.svg'
                                }}}>
                            </ThemeImage>
                        </button>
                    </Badge>
                    <div className="px-lg">
                        <h2>About Me</h2>
                    </div>
                </div>
            </Column>
            <Column className="w-6 w-mb-9 mx-lg">
                <div className="w-10 r-sm my-lg bg-tertiary" style={{height: '400px'}}>
                    <div className="px-lg">
                        <h2>Skillset</h2>
                    </div>
                </div>
                <div className="w-10 r-sm my-lg bg-tertiary" style={{height: '400px'}}>
                    <div className="px-lg">
                        <h2>Experience</h2>
                    </div>
                </div>
            </Column>
        </Row>
    </div>
);

export default Jacob;