import React, { Component } from 'react';
import HighlightImage from './image/highlight-image';
import HighlightCard from './card/highlight-card';

import './highlight.css';

class Highlight extends Component {

    // The last known window width
    windowWidth;

    originalHeight;
    expandedHeight;
    card;

    constructor(props) {
        super(props);
        this.originalHeight = this.props.height || '100px';
        this.expandedHeight = this.props.expandedHeight || this.originalHeight;

        this.state = {
            expanded: this.props.expanded || false,
            height: (this.props.expanded ? this.expandedHeight : this.originalHeight)
        };

        // Bind the component to the functions
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.collapseOnResize = this.collapseOnResize.bind(this);
        this.toggleOnClick = this.toggleOnClick.bind(this);
        this.toggleExpanded = this.toggleExpanded.bind(this);
    }

    componentDidMount() {
        if (this.card != null) {
            this.expandedHeight = this.refs.card.componentHeight;
        }

        // Update the last window width
        this.windowWidth = window.innerWidth;

        // Bind the window resize event
        window.addEventListener("resize", this.collapseOnResize);
        document.addEventListener('mousedown', this.toggleOnClick);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleClickOutside);
        document.removeEventListener('mousedown', this.toggleOnClick);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    collapseOnResize() {

        const dif = this.windowWidth - window.innerWidth;

        if (dif > -5 && dif < 5) {
            return;
        }

        // Update the last window width
        this.windowWidth = window.innerWidth;

        if (this.state.expanded) {
            this.toggleExpanded();
        }
    }

    toggleOnClick(event) {
        if (this.wrapperRef && this.wrapperRef.contains(event.target)) {
            this.toggleExpanded();
        }
    }

    toggleExpanded() {
        if (this.originalHeight === this.expandedHeight) {
            return;
        }

        // Update the component height if it has changed
        if (this.card != null && this.refs.card.componentHeight > this.originalHeight) {
            this.expandedHeight = this.refs.card.componentHeight;
        }

        this.setState(state => {

            // Attempt to show/hide a child card if present
            if (this.card != null) {
                this.refs.card.setHeight(state.expanded ? this.originalHeight : this.expandedHeight);
            }
            
            // Resize the image if present
            if (this.image != null) {
            	this.refs.image.setHeight(state.expanded ? this.originalHeight : this.expandedHeight);
            }

            return {
                height: (state.expanded ? this.originalHeight : this.expandedHeight),
                expanded: !state.expanded
            }
        });
    }

    render = () => {
        const bgImage = this.props.bgImage;
        const bgColor = this.props.bgColor || 'bg-tertiary';
        const color = this.props.color || 'primary';
        const side = this.props.side || 'right';
        const imageSrc = this.props.imageSrc;
        const imageAlt = this.props.imageAlt;
        const cardTitle = this.props.cardTitle;
        const cardChildren = this.props.cardChildren;

        let background;
        let expand;

        if (bgImage != null) {
            background = <img src={bgImage} alt="Highlight background" className="bg-image" />;
        }
        
        if (imageSrc != null) {
        	this.image = <HighlightImage ref="image" source={imageSrc} alt={imageAlt}
        					float={side === 'right' ? 'left' : 'right'} height={this.originalHeight} />
        }

        if (cardTitle != null) {
            this.card = React.createRef();
            this.card = <HighlightCard ref="card" title={cardTitle} color={color}
                            float={side} height={this.originalHeight} children={cardChildren} />;
        }

        if (this.originalHeight !== this.expandedHeight) {
            expand = <button className="btn expand-btn" onClick={this.toggleExpanded}>{this.state.expanded ? 'Show less' : 'Show more'}</button>
        }

        return(
            <div className={this.props.className}>
                <div ref={this.setWrapperRef} className={'highlight-container ' + bgColor} style={{height: this.state.height}}>
                    {background}
                    <div className="content">
                        <div className="m-auto w-max">
                        	{this.image}
                            {this.card}
                        </div>
                    </div>
                    <div className={'accent accent-' + side + ' ' + color} />
                </div>
                {expand}
            </div>
        );
    }
}

export default Highlight;