import React from 'react'
import ConnectForm from './connect-form';
import ConnectSocial from './connect-social';
import '../../shared/scripts/animations/float-in/float-in';

const ConnectSection = () => (
    <div>
        <div id="connect" className="text-center m-lg float-in">
            <div className="m-auto w-8 w-mb-9 w-max">
                <h1>Connect with us</h1>
                <hr className="primary m-auto w-3 w-mb-8" />
                <br/>
            </div>
            <ConnectForm />
        </div>
        
        <ConnectSocial />
    </div>
        
)

export default ConnectSection;