import React, { Component } from 'react';
import DynamicImage from '../image/dynamic-image';

import './loading-overlay.css';
import Column from '../layout/column';
import Row from '../layout/row';

class LoadingOverlay extends Component {
	
	count;
	delay;

    constructor(props) {
        super(props);        
        
        this.state = {
        	component: undefined,
        	displaying: false,
        	animating: false,
        	activeIndex: -1,
        	points: []
        }
        
        // Set the count of points to display when animating and the animation delay
        this.count = (isNaN(props.count) ? 3 : props.count);
        this.delay = (isNaN(props.delay) ? 500 : props.delay);
        
        // Add an element for each point
        for (let i = 0; i < this.count; i++) {
        	
        	this.state.points[i] = {
        		key: i,
        		alt: 'Loading...',
        		className: 'm-sm',
        		images: {
        			'inactive': { 
                		'src': '/images/icons/loading-1.png',
                	},
                	'active': {
                		'src': '/images/icons/loading-2.png'
                	}
                }
        	}
        }
    }
    
    setDisplaying = (displaying) => {
    	let state = this.state;
    	state.displaying = displaying;
    	this.setState(state);
    }
    
    setAnimating = (animating) => {
    	
    	// Return if already animating
    	if (animating && this.state.animating) {
    		return;
    	}
    	
    	let state = this.state;
    	state.animating = animating;
    	
    	this.setState(state);
    	
    	// If true start the overlay animation
    	if (animating) {
    		this._animate();
    	}
    }
    
    showComponent = (component, delay?: number, hideAfter?: number) => {
    	this.setDisplaying(true);
    	this.setAnimating(false);
    	
    	// Set the component to display in the overlay
    	this.setState({
    		component: component
    	})
    	
    	setTimeout(() => {
    		
    		// Remove the display none from the container
    		this.refs['overlay-component'].classList.add('o-10');
    		this.refs['overlay-component'].classList.remove('o-0');
    		this.refs['overlay-component'].classList.remove('d-none');
    		
    		// Hide the component after a delay if provided
    		if (hideAfter > 0) {
    			
    			setTimeout(() => {
    				this.refs['overlay-component'].classList.remove('o-10');
    				this.refs['overlay-component'].classList.add('o-0');
    				this.refs['overlay-component'].classList.add('d-none');
    				this.setDisplaying(false);
    			}, hideAfter);
    		}
    	}, delay);
    }
    
    _animate = () => {
    	
    	// Stop the animation if animating is false
    	if (!this.state.animating) {
    		return;
    	}
    	
    	// Get the current active index and set the next index
    	let active = this.state.activeIndex;
    	let next = (active > this.count ? 0 : active + 1);

    	// Call a state change
    	this.setState({
    		activeIndex: next
    	});
    	
    	// Call the animate function after the delay
    	setTimeout(this._animate, this.delay);
    }

    render = () => {    	
        return(
            <div className={'loading-overlay ' + (this.state.animating || this.state.displaying ? 'd-block' : 'd-none')}>
            	<Column className="h-10 text-center">
            		<div ref="overlay-component" className="m-lg o-0 d-none">
            			{this.state.component}
            		</div>
            		
            		<Row className="flex" style={{ opacity: (this.state.animating ? '1' : '0') }}>
	            		{ this.state.points.map((point) => 
	            			<DynamicImage key={point.key} selected={(this.state.activeIndex === point.key ? 'active' : 'inactive')}
	            				alt={point.alt} className={point.className} images={point.images}>
	            			</DynamicImage>
	            		)}
	            	</Row>
	            </Column>
            </div>
        );
    }
}

export default LoadingOverlay;