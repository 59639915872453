export default class Request {
	
	url: string;
	timeout: number;
	onSuccess;
	onError;
	onTimeout;

	constructor(url, timeout = 60000, onSuccess?, onError?, onTimeout?) {
		this.url = url;
		this.timeout = timeout;
		this.onSuccess = onSuccess;
		this.onError = onError;
		this.onTimeout = onTimeout;
		
		this.updateOnSuccess = this.updateOnSuccess.bind(this);
		this.updateOnError = this.updateOnError.bind(this);
		this.updateOnTimeout = this.updateOnTimeout.bind(this);
		this.getJSON = this.getJSON.bind(this);
		this.postJSON = this.postJSON.bind(this);
	}
	
	updateOnSuccess(callback) {
		this.onSuccess = callback;
	}
	
	updateOnError(callback) {
		this.onError = callback;
	}
	
	updateOnTimeout(callback) {
		this.onTimeout = callback;
	}

	getJSON(): XMLHttpRequest {
		let request = this._openJSONRequest('GET', this.onSuccess, this.onError, this.onTimeout, this.timeout); 
		request.send();
		return request;
	}
	
	postJSON(data): XMLHttpRequest {
		let request = this._openJSONRequest('POST', this.onSuccess, this.onError, this.onTimeout, this.timeout);
		request.send(JSON.stringify(data));
		return request;
	}
	
	_openJSONRequest(type, onSuccess, onError?, onTimeout?, timeout?): XMLHttpRequest {
		let request = this._openRequest(type, onSuccess, onError, onTimeout, timeout); 
		request.setRequestHeader('Content-Type', 'application/json');
		return request;
	}
	
	_openRequest(type, callback?, errorCallback?, timeoutCallback?, timeout = 60000): XMLHttpRequest {
		let request = new XMLHttpRequest();
		request.open(type, this.url);
		request.timeout = timeout;
		request.onload = callback;
		request.onerror = errorCallback;
		request.ontimeout = (timeoutCallback === undefined ? errorCallback : timeoutCallback);
		return request;
	}
}