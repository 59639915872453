import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as Browser from '../../shared/scripts/browser';
import { toggleColorTheme } from '../../shared/scripts/theme';
import ThemeImage from '../../shared/components/image/theme-image';
import './header.css';

import '../../shared/scripts/animations/anchor-scroll';

export const NAV_OPEN = 0;
export const NAV_CONDENSED = 1;
export const NAV_ONLY_LOGO = 2;

class PageHeader extends Component {
	
	navState;
	mobileMenu = false;
	animating = false;
	
	cursorLastMove = 0;
	cursorX = 0;
	cursorY = 0;
	
	constructor(props) {
		super(props);
		this.updateOnInteract = this.updateOnInteract.bind(this);
		this.updateOnMove = this.updateOnMove.bind(this);
		this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
	}

	componentDidMount() {
        window.addEventListener("scroll", this.updateOnInteract);
        window.addEventListener("resize", this.updateOnInteract);
		window.addEventListener("mousemove", this.updateOnMove);
		window.addEventListener("routeChange", () => this.animateMobileMenu(false));
    }
	
	isWithinNav() {
    	const distance = window.pageYOffset;
    	const header = document.getElementById("header");
    	const nav = header.querySelector("#nav");
    	return distance < nav.offsetHeight;
	}
	
	isWithinHeader() {
		const header = document.getElementById("header");
		const nav = header.querySelector("#nav");
		return this.cursorY < nav.offsetHeight;
	}
	
	updateOnInteract(event) {
		if (Browser.isMobile() && (event === undefined || (event.type !== 'scroll' && event.type !== 'resize'))) {
			return;
		}
		
    	if (this.isWithinNav()) {    		
    		this.updateNavigation(NAV_OPEN);
    		return;
    	}

    	this.updateNavigation(this.isWithinHeader() ? NAV_CONDENSED : NAV_ONLY_LOGO);
    }
    
    updateOnMove(event) {    
    	this.cursorY = event.clientY;
    	this.updateOnInteract();
    }
    
    updateNavigation(state) {
    	if (this.animating) {
    		return;
    	}
    	
    	if (Browser.isMobile()) {
    		this.animateMobileMenu(false);
    		state = NAV_OPEN;
    	}
    	
    	if (this.navState === state) {
    		return;
    	}
    	
    	this.navState = state;
    	let header = document.getElementById("header");
    	let nav = header.querySelector("#nav");
    	let menu = nav.querySelector("#menu");
    	let links = nav.querySelectorAll(".nav-link");
    	let logo = nav.querySelector("#nav-logo")
    	
    	switch (state) {
    	
    	case NAV_OPEN:
    		menu.classList.remove("nav-menu-scrolling");
	    	logo.classList.add("btn-img-lg");
	    	logo.classList.remove("btn-img-md");
	    	nav.style.opacity = null;
	    	nav.style.height = null;
	    	
	    	this.animateLinks(0, true, links);
	    	break;
    		
    	case NAV_CONDENSED:
    		menu.classList.add("nav-menu-scrolling");
        	logo.classList.add("btn-img-md");
        	logo.classList.remove("btn-img-lg");
        	nav.style.opacity = 0.98;
        	nav.style.height = "56px";
        	
        	this.animateLinks(0, true, links);
        	break;
    		
    	case NAV_ONLY_LOGO:
    		menu.classList.add("nav-menu-scrolling");
    		logo.classList.add("btn-img-sm");
        	logo.classList.remove("btn-img-md");
        	logo.classList.remove("btn-img-lg");
    		nav.style.opacity = 0.98;
    		nav.style.height = "46px";
    		
    		this.animateLinks(0, false, links);
    		break;
    		
    	default:
    		return;
    	}
    }
    
    toggleMobileMenu() {
    	this.animateMobileMenu(!this.mobileMenu);
    }
    
    animateMobileMenu(show) {
    	if (!Browser.isMobile() || this.animating) {
    		return;
    	}
    	
    	this.animating = true;
    	let menu = document.getElementById("mobile-menu");
    	menu.style.top = (show ? "60px" : null);
    	
    	setTimeout(() => {
    		this.animating = false;
    	}, 1000);
    	
    	this.mobileMenu = show;
    }
    
    animateLinks(start, show, elements) {
    	if (start >= elements.length) {
    		this.animating = false;
    		this.updateOnInteract();
    		return;
    	}
    	
    	this.animating = true;
    	let end = elements.length - (start + 1);
    	let color = show ? null : "var(--tertiary-bg-color)";
    	
    	const firstElement = elements[start];
    	const lastElement = (end > start ? elements[end] : null); 

    	firstElement.style.color = color;
    	
    	if (lastElement != null) {
    		lastElement.style.color = color;
    	}
    	
    	setTimeout(() => {
    		firstElement.style.borderColor = color;
    		if (lastElement != null) {
        		lastElement.style.borderColor = color;
        	}
    		setTimeout(this.animateLinks(start += 1, show, elements), 125);
    	}, 250);
    	
    }

    render = () => {
        return(
            <header id="header">
            	<div id="nav" className="nav">
	                <div id="menu" className="flex flex-a-items-start flex-a-items-mb-center m-auto w-max nav-menu">
	
	                	<div className="flex flex-end">
		                	<button className="btn btn-primary mx-lg r-sm d-none d-mb-block" onClick={this.toggleMobileMenu}>
			                	<ThemeImage alt="Toggle menu" className="btn-img-icon" images={{ 
	                            	'dark': { 
	                            		'src': '/images/icons/menu-white.svg',
	                            	},
	                            	'light': {
	                            		'src': '/images/icons/menu.svg'
	                            	}}}>
				            	</ThemeImage>
			            	</button>
	                	
		                    <Link to="/" className="btn btn-primary-underline nav-link d-mb-none">
		                    	Home
		                    </Link>

							<Link to="/team" className="btn btn-primary-underline nav-link d-mb-none">
		                    	Team
		                    </Link>
	                    </div>
	
	                    <div className="r-md bg-tertiary">
							<Link to="/" onClick={this.updateOnInteract}>
	                    		<img src="/images/cs-logo-icon.png" className="btn-img-lg mx-md my-sm" id="nav-logo" alt="Culley Studios logo"></img>
							</Link>
						</div>
	
	                    <div className="flex flex-start">
							<button className="btn btn-secondary mx-lg r-sm d-none d-mb-block" onClick={toggleColorTheme}>
			                    <ThemeImage alt="Toggle theme" className="btn-img-icon" images={{ 
	                            	'dark': { 
	                            		'src': '/images/icons/sun.svg',
	                            	},
	                            	'light': {
	                            		'src': '/images/icons/moon.svg'
	                            	}}}>
				            	</ThemeImage>
			            	</button>
							
							<Link to="/store" className="btn btn-secondary-underline nav-link d-mb-none">
		                    	Store
		                    </Link>
							
							<a href="/docs" className="btn btn-secondary-underline nav-link d-mb-none">
		                    	Docs
		                    </a>
	                    </div>
	                </div>
	            </div>
	            <div id="mobile-menu" className="mobile-menu d-mb-block">
	            	<div className="flex flex-wrap mx-sm">
	            
						<Link to="/" className="btn btn-primary-underline w-4">
		                	Home
		                </Link>
		                	
		                <Link to="/team" className="btn btn-secondary-underline w-4">
		                	Team
		                </Link>
		                	
		                <Link to="/store" className="btn btn-primary-underline w-4">
	                    	Store
	                    </Link>
	                    	
	                    <a href="/docs" className="btn btn-secondary-underline w-4">
	                    	Docs
	                    </a>
	                </div>
	            </div>
	            <br/>
            </header>
        );
    }
}

export default PageHeader;