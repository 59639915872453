import DynamicImage from './dynamic-image';

class ThemeImage extends DynamicImage {

    constructor(props) {
        super(props);
        this.state.selected = window.currentTheme;
    }

    componentDidMount() {
    	super.componentDidMount();
        window.addEventListener("theme", this.onThemeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('theme', this.onThemeChange);
    }
    
    onThemeChange = () => {
    	this.setSelected(window.currentTheme);
    }
}

export default ThemeImage;