import React, { Component } from 'react'
import './card.css'

class Card extends Component {

    getSection = (classes, children) =>
        children ? <div className={'card-section ' + (classes ? classes : '')}>{children}</div> : null

    render = () => {
        const style = {
            borderRadius: (this.props.radius ? this.props.radius : null),
        }

        return (
            <div className={'card ' + (this.props.className ? this.props.className : '')} style={style}>
                {this.getSection(this.props.headerClass, this.props.header)}
                {this.getSection(this.props.bodyClass, this.props.body)}
                {this.getSection(this.props.footerClass, this.props.footer)}
                {this.props.children}
            </div>
        );
    }
}

export default Card;