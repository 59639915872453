import FormField from  './form-field';
import Request from '../../classes/communication/request';

export default class Form {
	
	id: string;
	parent;
	fields = {};
	touched = false;
	submitting = false;
	selected: string;

	constructor(id, parent?, fields?: FormField[]) {
		
		let bindTo = this;
		
		// Add this form to the parent state if provided
		if (parent !== undefined) {
			
			let state = (parent.state !== undefined ? parent.state : {});
			state[id] = this;
			parent.state = state;
			
			// Set the bind property as the parent form state
			bindTo = parent.state[id];
			
		}
		
		this.id = id;
		this.parent = parent;
		this.getValues = this.getValues.bind(bindTo);
		this.getValue = this.getValue.bind(bindTo);
		this.setValue = this.setValue.bind(bindTo);
		this.setFields = this.setFields.bind(bindTo);
		this.setField = this.setField.bind(bindTo);
		this.isValid = this.isValid.bind(bindTo);
		this.isFieldValid = this.isFieldValid.bind(bindTo);
		this.canSubmit = this.canSubmit.bind(bindTo);
		this.updateState = this.updateState.bind(bindTo);
		this.labelFor = this.labelFor.bind(bindTo);
		this.input = this.input.bind(bindTo);
		this.select = this.select.bind(bindTo);
		this.submit = this.submit.bind(bindTo);
		this._submitCallback = this._submitCallback.bind(bindTo);
		this.clear = this.clear.bind(bindTo);
		
		// Set the initial fields if provided
		if (fields !== undefined) {
			this.setFields(fields);
		}
	}
	
	getValues() {
		// Define the array to add the elements to
		const array = {};
		
		// Iterate through each field and append the value variable
		Object.keys(this.fields).forEach(key => {
			array[key] = this.fields[key].value;
		});
		
		return array;
	}
	
	getValue(id: string) {
		// Ensure that the field exists
		if (this.fields[id] === undefined) {
			this.fields[id] = new FormField(id);
		}
		return this.fields[id].value;
	}
	
	setValue(id: string, value, validate = true, update = true) {
		this.fields[id].value = value;
		
		// Check if the field should be validated
		if (validate) {
			this.fields[id].isValid();
		}
		
		this.updateState(update);
	}
	
	setFields(fields: FormField[]) {
		fields.forEach(field => {
			this.setField(field);
		})
	}
	
	setField(field: FormField) {
		this.fields[field.id] = field;
	}
	
	isValid(update = true): boolean {
		
		let valid = 0;
		
		// Increment the valid count for each successful validation
		for (let key of Object.keys(this.fields)) {
			valid += this.isFieldValid(key, false) ? 1 : 0;
		}
		
		// Check if the form state should be updated
		this.updateState(update);
		
		return Object.keys(this.fields).length === valid;
	}
	
	isFieldValid(id: string, update = true): boolean {
		
		const valid = this.fields[id].isValid();
		
		// Check if the form state should be updated
		this.updateState(update);
		
		return valid;
	}
	
	canSubmit() {
		return !this.submitting && this.isValid(false);
	}
	
	updateState(update = true) {
		// If a parent is defined trigger a state change
		if (update && this.parent !== undefined) {
			this.parent.setState(this.parent.state);
		}
	}
	
	labelFor(id: string) {
		return this.fields[id].label;
	}
	
	input(event) {
		// Set the value in the form fields
		this.setValue(event.target.name, event.target.value);
		
		// Set the field and form as touched
		this.fields[event.target.name].touched = true;
		this.touched = true;
		
		// Update the target element with the value
		event.target.value = this.getValue(event.target.name);
	}
	
	select(event) {
		// Return if the selected element was already selected
		if (event.target.name === this.selected) {
			return;
		}
		
		// Validate the now unselected field
		if (this.selected !== undefined) {
			this.isFieldValid(this.selected);
		}
		
		// Update the selected field id
		this.fields[event.target.name].touched = true;
		this.selected = event.target.name;
	}
	
	submit(request: Request): XMLHttpRequest {
		
		// Validate if the form is eligible for submission
		if (!this.canSubmit()) {
			return undefined;
		}
		
		// Get the defined callbacks
		const success = request.onSuccess;
		const error = request.onError;
		const timeout = request.onTimeout;
		
		// Assign a custom callback to update the submit variable on completion
		request.onSuccess = (data) => { this._submitCallback(data, success) };
		request.onError = (data) => { this._submitCallback(data, error) };
		request.onTimeout = (data) => { this._submitCallback(data, timeout) };

		// Get the values to be passed to the web-service
		const values = this.getValues();
		
		// Set submitting as true
		this.submitting = true;
		this.updateState();
		
		// Execute the POST for the request
		return request.postJSON(values);
	}
	
	_submitCallback(data, callback) {
		this.submitting = false;
		this.updateState();
		
		if (callback !== undefined) {
			callback(data);
		}
	}
	
	clear() {
		for (let key in this.fields) {
			this.fields[key].clear();
		}
		// Set form as untouched when cleared
		this.touched = false;
		this.updateState();
	}
}