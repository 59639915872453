import React, { Component } from 'react';

class Column extends Component {

    render = () => {

        const className = (this.props.className ? ' ' + this.props.className : '');

        return (
            <div className={'flex flex-column' + className} style={this.props.style}>
                {this.props.children}
            </div>
        );
    }
}

export default Column;