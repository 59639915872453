import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './document.css';

export default class NavSection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            renderCount: props.renderCount ?? 5,
            renderIncrement: props.renderIncrement ?? 5,
            path: props.path,
            title: props.title,
            parentMatch: props.parentMatch,
            nested: props.nested
        }
    }

    render = () => {
        const remaining = this.state.nested.length - this.state.renderCount;
        const nested = this.state.nested.map((link, idx) => <div key={idx} className={idx >= this.state.renderCount ? 'd-none' : ''}>{link}</div>)

        const showMorePages = () => this.setState((state) => ({ ...state, renderCount: state.renderCount + state.renderIncrement }))

        const button = (
            <button className='btn btn-md btn-secondary-outline r-sm w-10' onClick={showMorePages} >
                Show {Math.min(remaining, this.state.renderIncrement)} more pages
            </button>
        )

        return (
            <ul className="pages-nav" key={this.state.path}>
                {this.state.parentMatch ? <li><Link to={this.state.path} className="pages-link">{this.state.title}</Link></li> : undefined}
                {nested}
                {remaining > 0 && button}
            </ul>
        )
    }
}