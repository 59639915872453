import React, { Component } from 'react';
import * as Browser from '../../scripts/browser';

class DynamicImage extends Component {

	mobile = false;

    constructor(props) {
		super(props);
		
		// Update the initial state of the browser
        this.mobile = Browser.isMobile();

        // Define a default state
        this.state = {
			hasMobileVariant: (props.hasMobileVariant !== undefined ? props.hasMobileVariant : false),
        	selected: undefined,
            images: {}
		};
		
		let images = props.images !== undefined ? props.images : {};

		// Check if an individual image is provided
		if (props.src !== undefined) {
			images = {...images, 'img': { src: props.src }}
		}
        
        // Define each of the image elements
        for (let key of Object.keys(images)) {

			this.state.images[key] = this.getImageData(props, images[key]);

			// Load the mobile image variant
			if (this.mobile && this.state.hasMobileVariant) {

				const src = images[key].src;
				const index = src.lastIndexOf('.');
				const mbKey = key + '-mb';
				const mbSrc = src.substring(0, index) + '-mb' + src.substring(index);
				const mbData = {...images[key], src: mbSrc }

				this.state.images[mbKey] = this.getImageData(props, mbData);
				this._loadMobileImage(mbKey, mbSrc);
			}
		}
		
		// Update the default selected image
		const selected = props.selected !== undefined ? props.selected : Object.keys(images)[0];
        this.state.selected = selected + (this.mobile && this.state.hasMobileVariant ? '-mb' : '');
	}
	
	componentDidMount() {
    	if (!this.mobile) {
    		return;
    	}
        window.addEventListener("resize", this.onResize);
	}
	
	componentWillUnmount() {
        window.removeEventListener("resize", this.onResize);
    }
    
    componentWillReceiveProps(props) {
    	this.setSelected(props.selected);
	}
	
	onResize = () => {
    	if (!this.mobile || Browser.isMobile())
    		return;
    	
    	// Remove the listener and update the local state
    	window.removeEventListener('resize', this.onResize);
    	this.mobile = false;
    	
    	// Return if not already viewing a mobile object
    	if (!this.state.selected.endsWith('-mb')) {
    		return;
		}
    	
    	const key = this.state.selected.replace('-mb', '');
    	this.setSelected(key);
    }
    
    setSelected = (key: string) => {
		// If a mobile version should be shown, update the key
		if (this.state.hasMobileVariant && Browser.isMobile() && this.state.images[key]) {
			key = key += 'mb';
		}
    	
    	// Check to ensure an image with the key exists before updating
    	if (!this.state.images[key])
    		return;
    	
    	let updated = this.state;
    	updated.selected = key;
    	this.setState(updated);
	}

	getImageData = (props, { src, alt, className, style }) => {
    	return {
    		src: src,
    		alt: (alt !== undefined ? alt : props.alt),
			className: (className !== undefined ? className : props.className),
			style: (style !== undefined ? style : props.style)
    	}
    }

	_loadMobileImage = (key, src) => {
		let img = new Image();
        	
        // If the mobile image doesn't exist remove the key and use the original
        img.onError = () => {
			const state = this.state;

			if (state.images[key]) {
				state.images[key] = null;
				this.setState(state);
			}

			this.setSelected(this.state.selected.replace('-mb', ''));
        };
        	
        // Set the src to initialize the load
        img.src = src;
	}

    render = () => {
    	const data = this.state.images;
    	
    	return (
    		<img src={data[this.state.selected].src} 
				className={data[this.state.selected].className}
				style={data[this.state.selected].style}
    			alt={data[this.state.selected].alt}>
    		</img>
    	)
    }
}

export default DynamicImage;