import FieldValidator from '../field-validator';

export default class MinLengthValidator extends FieldValidator {
	
	length: number;
	
	constructor(error: string, length: number) {
		super(error);
		this.length = length;
	}
	
	validateField(field: FormField): boolean {
		return field.value.length >= this.length;
	}
}