import React from 'react';
import TimedSlideshow from '../../shared/components/slideshow/timed-slideshow';
import SlideshowElement from '../../shared/components/slideshow/element/slideshow-element';
import DynamicImage from '../../shared/components/image/dynamic-image';
import Highlight from '../../shared/components/highlight/highlight';
import ConnectSection from '../connect/connect-section';
import '../../shared/scripts/animations/float-in/float-in';
import '../../index.css';
import './home.css';
import { Link } from 'react-router-dom';
import { useFloatInTimeout } from '../../shared/hooks/use-float-in-timeout';

const Home = () => {
	useFloatInTimeout(60000)

	const serviceElements = [
		<SlideshowElement className="services-icon" id="services-react" key="react">
			<DynamicImage src="/images/services/react.png" alt="React"></DynamicImage>
		</SlideshowElement>,
		<SlideshowElement className="services-icon" id="services-nodejs" key="nodejs">
			<DynamicImage src="/images/services/nodejs.png" alt="NodeJS"></DynamicImage>
		</SlideshowElement>,
		<SlideshowElement className="services-icon" id="services-angular" key="angular">
			<DynamicImage src="/images/services/angular.png" alt="Angular"></DynamicImage>
		</SlideshowElement>,
		<SlideshowElement className="services-icon" id="services-nestjs" key="nestjs">
			<DynamicImage src="/images/services/nestjs.png" alt="NestJS"></DynamicImage>
		</SlideshowElement>,
		<SlideshowElement className="services-icon" id="services-html" key="html">
			<DynamicImage src="/images/services/html.png" alt="HTML"></DynamicImage>
		</SlideshowElement>,
		<SlideshowElement className="services-icon" id="services-css" key="css">
			<DynamicImage src="/images/services/css.png" alt="CSS"></DynamicImage>
		</SlideshowElement>,
		<SlideshowElement className="services-icon" id="services-javascript" key="javascript">
			<DynamicImage src="/images/services/javascript.png" alt="JavaScript"></DynamicImage>
		</SlideshowElement>,
		<SlideshowElement className="services-icon" id="services-java" key="java">
			<DynamicImage src="/images/services/java.png" alt="Java"></DynamicImage>
		</SlideshowElement>,
		<SlideshowElement className="services-icon" id="services-python" key="python">
			<DynamicImage src="/images/services/python.png" alt="Python"></DynamicImage>
		</SlideshowElement>,
		<SlideshowElement className="services-icon" id="services-swift" key="swift">
			<DynamicImage src="/images/services/swift.png" alt="Swift"></DynamicImage>
		</SlideshowElement>,
		<SlideshowElement className="services-icon" id="services-git" key="git">
			<DynamicImage src="/images/services/git.png" alt="Git"></DynamicImage>
		</SlideshowElement>
	];

	return (
		<div className="text-center">
			{/* ------------------------
				About us section
			------------------------ */}
			<div id="about" className="my-lg float-in">
				<div className="m-auto w-8 w-mb-9 w-max">
					<div className="text-left float-in">
						<h1 className="text-center">About Us</h1>
						<hr className="primary m-auto w-3 w-mb-8" />
						<br/>
						<p>
							Culley Studios is a software company focused on application development throughout all stages of the software development life cycle. Since 2017 we’ve provided our clients with development, administration, and consultancy services while also managing a number of in-house applications.
						</p>
					</div>
				</div>
			</div>

			{/* ------------------------
				Services section
			------------------------ */}
			<div id="services" className="my-lg float-in">
				<div className="m-auto w-8 w-mb-9 w-max">
					<div className="text-left float-in">
						<h1 className="text-center">Services</h1>
						<hr className="primary m-auto w-3 w-mb-8" />
						<br/>
						<p>
							As a complete solutions company we offer application design, fullstack development, system design, and system administration. From planning to implementation we can handle every stage of development. We have experience working with a number of languages, frameworks, and services giving us the ability to meet your specific project requirements.
						</p>
						<p>
							If you’re looking to <b><a href="#connect" className="text-primary">hire us</a></b>, or if you have any questions regarding the services we offer, we encourage you to <b><a href="#connect" className="text-primary">contact us.</a></b>
						</p>
					</div>
				</div>
			</div>

			<TimedSlideshow 
				animationDelay="1500"
				spaceBetween="20"
				looping="true"
				inverted="true"
				elements={serviceElements}
				className="w-10 my-lg bg-tertiary float-in"
				height="120px">
			</TimedSlideshow>

			{/* ------------------------
				Clients section
			------------------------ */}
			<div id="clients" className="my-lg float-in">
				<div className="m-auto w-8 w-mb-9 w-max">
					<div className="text-left float-in">
						<h1 className="text-center">Clients</h1>
						<hr className="primary m-auto w-3 w-mb-8" />
						<br/>
						<p>
							We’ve worked with a number of organizations, providing unique services based on their business cases. We’re always interested in new client relationships and look forward to discussing potential opportunities where we can help you meet your project goals. Below are just a few of the clients we’ve had the pleasure of working with.
						</p>
					</div>
				</div>
			</div>

			<Highlight className="float-in"
				expandedHeight="200px"
				side="left"
				color="primary"
				bgColor="bg-secondary"
				imageSrc="/images/lemon-cloud-logo.png"
				imageAlt="Lemon Cloud Logo"
				cardTitle="Lemon Gaming"
				cardChildren={
					<div>
						<p>
							Lemon Gaming is the parent organization of a number of popular game networks including Desteria, and LemonCloud. These networks deliver a high quality gaming experience to thousands of unique players daily! 
						</p>
						<p>
							From late 2018 until mid 2019 we were contracted on a per project basis with a number responsibilities
							including code refactoring and feature enhancements. 
							
						</p>
						<p>
							Some of the work we completed includes:
						</p>
						<ul>
							<li>
								Converting an existing JavaScript based envoy event plugin into a Java application. The application handled dropping chests filled with items, and one-time rewards for players to collect.
							</li>
							<li>
								Added a custom world border option to an existing Java plugin, utilizing client packets to handle in-game border visual effects.
							</li>
						</ul>
					</div>
				}>
			</Highlight>

			<Highlight className="float-in"
				expandedHeight="500px"
				side="left"
				color="secondary"
				bgColor="bg-secondary"
				imageSrc="/images/primemc.png"
				imageAlt="PrimeMC Logo"
				cardTitle="Voldex Services"
				cardChildren={
					<div>
						<p>
							Voldex is a Canadian video games company operating some of the world's largest online gaming networks
							including TheArchon, PrimeMC, OPBlocks, and many others, amassing a total reach of 2 million gamers. 
						</p>
						<p>
							As an independent contractor from early to late 2017 we were responsible for a number of
							projects utilizing their existing technology stack consisting of Java, PHP, MySQL, and MongoDB.
							We developed complete applications while also providing analysis and patches for their existing codebase.
						</p>
						<p>
							Some of the work we completed includes:
						</p>
						<ul>
							<li>
								A full fledged staff management system with in-game user tracking, paired with a dynamic web
								application allowing for administrative monitoring of individual staff members.
							</li>
							<li>
								Assisted the development lead with root cause analysis, user acceptance testing, and developing patches for unexpected application behaviour reported by users.
							</li>
						</ul>
					</div>
				}>
			</Highlight>

			<Highlight className="float-in"
				expandedHeight="200px"
				side="left"
				color="tertiary"
				bgColor="bg-secondary"
				imageSrc="/images/timeless-logo.png"
				imageAlt="Timeless PvP Logo"
				cardTitle="Timeless PvP"
				cardChildren={
					<div>
						<p>
							Timeless PvP was one of the largest gaming networks in the industry averaging 1000+ players concurrently,
							with over 50+ staff members.
						</p>
						<p>
							Our services were contracted on a per project basis from December 2015 up until the
							server closure in May 2016. During this time we developed a number of Java applications while managing
							data through a MySQL backend.
						</p>
						<p>
							Some of the work we completed includes:
						</p>
						<ul>
							<li>
								A core Java application to handle standard user features including messaging, command execution, and
								time based operations.
							</li>
						</ul>
					</div>
				}>
			</Highlight>

			{/* ------------------------
				Offerings section
			------------------------ */}
			<div id="offerings" className="my-lg float-in">
				<div className="m-auto w-8 w-mb-9 w-max">
					<div className="text-left float-in">
						<h1 className="text-center">Offerings</h1>
						<hr className="primary m-auto w-3 w-mb-8" />
						<br/>
						<p>
							While our focus is on privately contracted services we also offer a number of products and services publicly. Our offerings range a wide span of product categories with a focus on end user experience. These offerings are provided and maintained exclusively by Culley Studios.
						</p>
					</div>
				</div>
			</div>
			
			<Highlight className="float-in"
				expandedHeight="200px"
				side="left"
				color="primary"
				bgColor="bg-secondary"
				imageSrc="/images/sl-logo-text.png"
				imageAlt="Server Locator Logo"
				cardTitle="Server Locator"
				cardChildren={
					<div>
						<p>
							Server Locator is a server listing index ranking Minecraft servers based on the number of votes received by users. Our service provides server owners an opportunity to list their servers while also giving players the opportunity to discover new servers to play on.
						</p>
						<p>
							Initially offered as a standalone iOS application Server Locator has evolved adding a desktop and mobile friendly web application to utilize the service. Our goal is to provide server owners with a user friendly and non intrusive way to promote their servers in an increasingly competitive market. We offer a number of options for increased exposure including premium memberships and sponsored slots.
						</p>
						<a href="https://serverlocator.com" className="btn btn-primary r-sm">Visit the Website</a>
						<br/>
					</div>
				}>
			</Highlight>

			<Highlight className="float-in"
				expandedHeight="200px"
				side="left"
				color="secondary"
				bgColor="bg-secondary"
				imageSrc="/images/spin-off-badge.png"
				imageAlt="Spin Off Logo"
				cardTitle="Spin Off"
				cardChildren={
					<div>
						<p>
							Spin Off is an arcade style game optimized for all iOS devices. With multiple game modes including “Time Trial”, “Marathon”, and “Sprint”, the objective is to spin the wheel which directly correlates to your score. Players scores are tracked and stored locally with social ranking leaderboards, and multiplayer functionality planned for a future release.
						</p>
						<p>
							Our goal with Spin Off is to expand our knowledge of Swift and iOS development while combining it with our abundance of experience developing applications for the gaming community.
						</p>
						<div className="w-10 mx-md my-lg">
							<a href="https://apple.co/2txwLcZ"><img src="/images/icons/apple-app-store-black.svg" className="w-4" alt="Download Spin Off"></img></a>
						</div>
						<br/>
					</div>
				}>
			</Highlight>
	
			<Highlight className="float-in"
				expandedHeight="200px"
				side="left"
				color="tertiary"
				bgColor="bg-secondary"
				cardTitle="Spigot Plugins"
				cardChildren={
					<div>
						<p>
							Over the years we've pioneered some game changing premium Spigot plugins with custom features many had never thought to incorporate into Minecraft servers. We've offered, and continue to offer many plugins which have been used on over 3000+ servers delivering high quality features for players!
						</p>
						<p>
							Some of these notable plugins include DailyBonus, ProQuests, ProVotes, and a number of others, all of which we still actively support and improve.
						</p>
						<Link to="/store" className="btn btn-tertiary r-sm">Visit the Store</Link>
						<br/>
					</div>
				}>
			</Highlight>
			
			{/* ------------------------
				Connect with us section
			------------------------ */}
			<ConnectSection/>
		</div>
	)
}
    		
export default Home;