import FieldValidator from './field-validator';

export default class FormField {

	id: string;
	value = '';
	label = '';
	touched = false;
	validators: FieldValidator[];
	
	constructor(id: string, validators: FieldValidator[] = []) {
		this.id = id;
		this.validators = validators;
		this.isValid = this.isValid.bind(this);
		this.clear = this.clear.bind(this);
	}
	
	isValid(): boolean {
		// Return true if there are no validations to check
		if (this.validators.length === 0) {
			return true;
		}
		
		// Iterate through each of the validators for this field
		for (const validator of this.validators) {
			
			// If valid move onto the next validator
			if (validator.validateField(this) === true) {
				continue;
			}
			
			// Set the label value
			this.label = this.touched ? validator.error : '';
			return false;
		}
		
		this.label = '';
		return true;
	}
	
	clear() {
		this.value = '';
		this.touched = false;
	}
}