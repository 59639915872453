import React, { Component } from 'react'
import LoadingOverlay from '../../shared/components/loading-overlay/loading-overlay';
import Request from '../../shared/classes/communication/request';
import Form from '../../shared/classes/form/form';
import FormField from '../../shared/classes/form/form-field';
import RequiredValidator from '../../shared/classes/form/validators/required-validator';
import MinLengthValidator from '../../shared/classes/form/validators/min-length-validator';
import MaxLengthValidator from '../../shared/classes/form/validators/max-length-validator';
import RegExValidator from '../../shared/classes/form/validators/regex-validator';
import Card from '../../shared/components/card/card';

class ConnectForm extends Component {

    messageForm = new Form('message-form', this, [
		new FormField('email', [
			new RequiredValidator('Please provide an email'),
			new RegExValidator('You must provide a valid email', '^[a-zA-Z0-9._+-]*@[a-zA-Z0-9.-]*\\.[a-zA-Z]{2,}$')
		]),
		new FormField('subject', [
			new RequiredValidator('Please provide a subject'),
			new MinLengthValidator('The subject must be at least 12 characters', 12),
			new MaxLengthValidator('The subject cannot be more than 64 characters', 64)
		]),
		new FormField('message', [
			new RequiredValidator('Please provide a message'),
			new MinLengthValidator('The message must be at least 60 characters', 60),
			new MaxLengthValidator('The message cannot be more than 2048 characters', 2048)
		]),
    ]);
    
    sendMessage = (event) => {
        event.preventDefault();
        
        const error = () => {
			this.refs['contact-form-overlay'].showComponent(
                    <span>An error occured while sending your message. Please try again or connect with us using one of the methods below.</span>, 1000, 7500);
            }
                    
        const timeout = () => {
            this.refs['contact-form-overlay'].showComponent(
                <span>A timeout occured while sending your message. Please try again or connect with us using one of the methods below.</span>, 1000, 7500);
            }
		
		// Submit the form to the web-service
		this.messageForm.submit(new Request('https://api.culleystudios.com/api/v1/contact/message', 30000, (response) => {
            if (response.target.status !== 201) {
                error();
                return;
            }

			this.refs['contact-form-overlay'].showComponent(
					<span>Your message was sent! We'll get back to you as soon as we can!</span>, 1000, 20000);
					
			this.messageForm.clear();
			
		}, error, timeout));
		
		// Display the loading overlay and start the animation
		this.refs['contact-form-overlay'].setAnimating(true);
	}

    render = () => {
        return (
            <div className="m-auto w-9 w-mb-10 w-max">
                <Card className="bg-tertiary" body={
                    <div className="flex flex-mb-wrap">
                        <div className="m-lg w-10">
                            <p>
                                If you are interested in speaking with us regarding an upcoming project, or have any other inquires, we encourage you to contact us! We make it a priority to respond to every inquiry we receive.
                            </p>
                            <p>
                                You can connect with us using any of the social platforms below, or you can fill out the form to send us a message!
                            </p>
                        </div>
                        
                        <div className="m-md w-10">
                            <Card className="bg-secondary pos-relative" body={
                                <form className="form" onSubmit={this.sendMessage}>
                                
                                    <div className="form-group">
                                        <div className="w-10">
                                            <label htmlFor="email" className="form-label float-left">Email</label>
                                            <label className="form-error float-right">{this.messageForm.labelFor('email')}</label>
                                        </div>
                                        <input type="text" className="form-input bg-primary" placeholder="Email address..." name="email" value={this.messageForm.getValue('email')} onChange={this.messageForm.input} onSelect={this.messageForm.select} />
                                    </div>
                                    
                                    <div className="form-group">
                                        <div className="w-10">
                                            <label htmlFor="subject" className="form-label float-left">Subject</label>
                                            <label className="form-error float-right">{this.messageForm.labelFor('subject')}</label>
                                        </div>
                                        <input type="text" className="form-input bg-primary" placeholder="Summarize your request/inquiry..." name="subject" value={this.messageForm.getValue('subject')} onChange={this.messageForm.input} onSelect={this.messageForm.select} />
                                    </div>
                                    
                                    <div className="form-group">
                                        <div className="w-10">
                                            <label htmlFor="message" className="form-label float-left">Message</label>
                                            <label className="form-error float-right">{this.messageForm.labelFor('message')}</label>
                                        </div>
                                        <textarea rows="6" className="form-input bg-primary" placeholder="Provide some additional information so we can better assist you..." name="message" value={this.messageForm.getValue('message')} onChange={this.messageForm.input} onSelect={this.messageForm.select} />
                                    </div>
                                    
                                    <div className="form-group">
                                        <input type="submit" className="form-input btn btn-primary" disabled={!this.messageForm.canSubmit()} />
                                    </div>
                                    
                                </form>
                            }>
                                <LoadingOverlay ref="contact-form-overlay" delay="100" />
                            </Card>
                        </div>
                    </div>
                }></Card>
            </div>
        );
    }
}

export default ConnectForm;