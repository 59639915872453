import React from 'react'

const DISCORD_URL = "https://discord.gg/jvFR3Ss";
const LINKEDIN_URL = "https://www.linkedin.com/company/culley-studios/";
const MESSENGER_URL = "https://m.me/culleystudios";
const FACEBOOK_URL = "https://www.facebook.com/CulleyStudios/";
const TWITTER_URL = "https://twitter.com/CulleyStudios";

const ConnectSocial = () => (
    <div>
        <div className="flex flex-wrap m-sm">
                        
            <a className="r-circle p-lg m-sm bg-tertiary float-in" href={DISCORD_URL}>
                <img src="/images/social/discord-icon.png" alt="Discord Icon" className="flex-a-self-center btn-img-md"/>
            </a>
            
            <a className="r-circle p-lg m-sm bg-tertiary float-in" href={LINKEDIN_URL}>
                <img src="/images/social/linkedin-icon.png" alt="LinkedIn Icon" className="flex-a-self-center btn-img-md"/>
            </a>
                        
            <a className="r-circle p-lg m-sm bg-tertiary float-in" href={MESSENGER_URL}>
                <img src="/images/social/messenger-icon.png" alt="Messenger Icon" className="flex-a-self-center btn-img-md"/>
            </a>
                            
            <a className="r-circle p-lg m-sm bg-tertiary float-in" href={FACEBOOK_URL}>
                <img src="/images/social/facebook-icon.png" alt="Facebook Icon" className="flex-a-self-center btn-img-md"/>
            </a>

            <a className="r-circle p-lg m-sm bg-tertiary float-in" href={TWITTER_URL}>
                <img src="/images/social/twitter-icon.png" alt="Twitter Icon" className="flex-a-self-center btn-img-md"/>
            </a>           
        </div>
    </div>
)

export default ConnectSocial