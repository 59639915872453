import React, { Component } from 'react';
import { BrowserRouter } from "react-router-dom";
import RouteChange from './route-change';

class EventRouter extends Component {
	
	render = () => {
		return (
			<BrowserRouter>
				<RouteChange ignoreLoad={this.props.ignoreLoad ?? false}></RouteChange>
				{this.props.children}
			</BrowserRouter>
		);
	}
}

export default EventRouter;