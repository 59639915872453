import { Component } from 'react';
import ReactGA from 'react-ga';

export default class GoogleAnalytics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: props.id,
            initialized: false,
        }
    }

    componentWillMount() {
        this.initialize();
        window.addEventListener('routeChange', this.addPageView);
    }

    componentWillUnmount() {
        window.removeEventListener('routeChange', this.addPageView);
    }

    initialize = () => {
        if (this.state.initialized)
            return;

        if (!this.state.id)
            throw Error('Unable to initialize GoogleAnalytics as no id was provided');

        ReactGA.initialize(this.state.id);
        this.setState({...this.state, initialized: true});
    }

    addPageView = (event) => {
        if (window.location.href.startsWith('http://localhost'))
            return;

        const location = event.detail.location;
        ReactGA.pageview(location.pathname + location.search);
    }

    render = () => (
        this.props.children
    )
}