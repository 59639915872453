import Slideshow from './slideshow';

class TimedSlideshow extends Slideshow {
	
	constructor(props) {
        super(props);
        this.state.startDelay = (props.startDelay !== undefined ? props.startDelay : 0);
		this.state.animationDelay = (props.animationDelay !== undefined ? props.animationDelay : 7500);
    }
    
    componentDidMount(props) {
    	super.componentDidMount(props);
		this.setState({...this.state,
			startDelay: (props && props.startDelay !== undefined ? props.startDelay : this.state.startDelay),
			animationDelay: (props && props.animationDelay !== undefined ? props.animationDelay : this.state.animationDelay),
			stopped: (props && props.stopped !== undefined ? props.stopped : this.state.stopped),
		});
		this.start();
    }
    
    componentWillUnmount() {
		super.componentWillUnmount();
		this.stop();
	}

	updateOnMouseMove = (event) => {
		if (!this.refs.container)
			return;
		
		return this.refs.container.contains(event.target) ? this.stop() : this.start();
	}

	start = () => {
		if (this.scheduler)
			return;
			
		this.setState({...this.state, stopped: false});
		this.scheduler = setTimeout(() => this._callUpdate(), (this.state.startDelay > 0 ? this.state.startDelay : this.state.animationDelay));
	}
	
	stop = () => {
		if (!this.scheduler)
			return;

		this.setState({...this.state, stopped: true});
		clearTimeout(this.scheduler);
		this.scheduler = 0;
	}
    
    _callUpdate = () => {
    	if (this.state.stopped)
    		return;
    	
		const element = document.getElementById(this.state.elements[this.state.activeIndex].props.id);
		
    	if (!element)
    		return;
    	
    	this.navigateRight();
    	this.scheduler = setTimeout(this._callUpdate, this.state.animationDelay);
    }
}

export default TimedSlideshow;