import React, { Component } from 'react';
import DynamicImage from '../../image/dynamic-image';
import './highlight-image.css';

class HighlightImage extends Component {

    // The original height of the rendered component
    componentHeight;

    height;
    margin;

    constructor(props) {
        super(props);

        // Retrieve the props height
        this.height = this.props.height;

        // Initialize the state variable
        this.state = {
            heightStyle: {}
        };

        // Bind the component to the functions
        this.setHeight = this.setHeight.bind(this);
    }

    componentDidMount() {
        this.componentHeight = this.getHeightWithMargin(this.refs.container.clientHeight);
        this.setHeight(this.height);
    }

    setHeight(height) {
        this.setState(() => ({
            heightStyle: {
                height: this.getHeightWithoutMargin(height)
            }
        }));
    }

    getHeightWithMargin(height) {
        height = height.toString().replace('px', '');
        const margin = getComputedStyle(document.documentElement).getPropertyValue('--lg-margin').replace('px', '');
        return parseInt(height) + (margin * 2) + 'px';
    }

    getHeightWithoutMargin(height) {
        height = height.toString().replace('px', '');
        const margin = getComputedStyle(document.documentElement).getPropertyValue('--lg-margin').replace('px', '');
        const total = height - (margin * 2);
        return (total > 280 ? 280 : total) + 'px';
    }

    render = () => {
        const source = this.props.source;
        const alt = this.props.alt;
        const float = this.props.float || 'right';

        return(
            <div ref="container" className={'highlight-image-container float-' + float} style={this.state.heightStyle}>
                <DynamicImage 
                    selected="img"
                    className={'float-' + float}
                    alt={alt}
                    hasMobileVariant="true"
                    images={{
                        'img': {
                            'src': source
                    }}}>
                </DynamicImage>
            </div>
        );
    }
}

export default HighlightImage;