import { useEffect, useRef } from 'react'
import { floatInElements } from '../scripts/animations/float-in/float-in'

export const useFloatInTimeout = (timeout) => {
    const ref = useRef({ timer: undefined })

    useEffect(() => {
        const { timer } = ref.current;
        if (timer) return;
        ref.current.timer = setTimeout(() => floatInElements(true), timeout)
        return () => {
            if (!timer) return
            clearTimeout(timer)
        }
    }, [ref, timeout])
}