import React from 'react'
import Helmet from 'react-helmet';
import ConnectSection from './connect-section';

const Connect = () => (
    <div>
        <Helmet>
            <title>Culley Studios | Connect with us</title>
            <meta name="description" content="Have an idea, upcoming project, or question? Connect with us and we'll
                help you with all of your software development needs!"/>
        </Helmet>
        <ConnectSection/>
    </div>
);

export default Connect;