import { Component } from 'react';
import { withRouter } from 'react-router'
import * as Browser from '../../scripts/browser';

class RouteChange extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loaded: props.ignoreLoad ?? false,
			ignoreLoad: props.ignoreLoad ?? false
		}
	}
	
	componentDidMount() {
		if (this.state.ignoreLoad)
			return;
			
		window.addEventListener('load', this.onLoad);
	}
	
	componentWillUnmount() {
		window.removeEventListener("load", this.onLoad);
	}

	componentDidUpdate(prevProps) {
		const location = this.props.location;
		
		if (prevProps.location.pathname === location.pathname)
			return;

		this.onRouteChange(location);
	}
	
	onLoad = () => {
		this.setState({...this.state, loaded: true});
		this.onRouteChange(this.props.location, false);
	}

	onRouteChange = (location, scrollToTop = true) => {
		if (scrollToTop)
			Browser.scrollToElement(document.getElementById('root'), 500, 0);

	    window.dispatchEvent(new CustomEvent('routeChange', { 
	    	detail: {
	    		loaded: this.state.loaded,
	    		location: location
	    	}
		}));
		
		try {
			(window.adsbygoogle || []).push({});
		} catch (err) {
			// do nothing
		}
	}
	
	render = () => {
		return null;
	}
}

export default withRouter(RouteChange);