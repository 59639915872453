import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import EventRouter from '../../shared/components/event-router/event-router';
import Request from '../../shared/classes/communication/request';
import DocumentPage from './document';

export default class Documents extends Component {

    constructor() {
        super();
        this.state = {
            redirect: undefined,
            rendered: false,
            pages: [],
        }
        this.updatePages('/');
    }

    shouldComponentUpdate(_nextProps, nextState) {
        return nextState.rendered !== true    
    }

    updatePages = (path) => {
        const redirectHandler = (error) => {
            console.error(error);
            this.setState({ ...this.state, redirect: (<Redirect to="/docs/" />)});
        }

        new Request(`https://api.culleystudios.com/api/v1/document/nested?path=${path}&limit=10000`, 15000, (data) => {
            this.setState({ ...this.state,
                pages: JSON.parse(data.target.response),
                redirect: (<Redirect to="/docs/" />)
            });
        }, redirectHandler, redirectHandler).getJSON();
    }

    render = () => {
        console.log('the state is', this.state)

        return (
            <EventRouter ignoreLoad="true">
                <Switch>
                    {
                        this.state.pages.map(page =>
                            <Route exact path={'/docs' + page.path}
                                key={page.path}
                                component={() => <DocumentPage
                                    path={page.path}
                                    pages={this.state.pages}
                                    rendered={this.state.rendered}
                                    setRendered={(rendered) => this.setState({ ...this.state, rendered })}
                                    navigation={generatePathNavigation(this.state.pages, "/docs")}/>}
                                />    
                        )
                    }
                    { this.state.redirect }
                </Switch>
            </EventRouter>
        )
    }
}

export const generatePathNavigation = (pages, path) => {
    const nested = pages.filter(page => page.path.startsWith(getParentPath(path)));
    return nested.length > 1 ? generateNavigation(nested) : generatePathNavigation(getParentPath(path));
}

export const getParentPath = (path) => {
    const formatted = path.charAt(path.length - 1) === '/' ? path.substring(0, path.length - 1) : path;
    return formatted.substring(0, formatted.lastIndexOf('/'));
}

export const generateNavigation = (pages) => {
    let trie = {}
    if (!pages) {
        return trie;
    }
    pages.forEach(page => {
        const parts = page.path.split('/');
        const cleaned = parts[0].length > 0 ? parts : parts.slice(1, parts.length);
        trie = {...trie, ...generateNode(trie, cleaned, page.title)};
    });
    return trie;
}

export const generateNode = (trie, parts, title) => {
    const next = parts.shift();
    if (!next || next.length === 0) {
        return;
    }
    return { [next]: {...trie[next], ...generateNode(trie[next] ?? {}, parts, title) ?? { pageTitle: title }}};
}