import FieldValidator from '../field-validator';

export default class RegExValidator extends FieldValidator {
	
	regex: RegExp;
	
	constructor(error: string, pattern: string) {
		super(error);
		this.regex = new RegExp(pattern);
	}
	
	validateField(field: FormField): boolean {
		return this.regex.test(field.value);
	}
}