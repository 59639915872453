import React, { Component } from 'react'
import Helmet from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import Column from '../../shared/components/layout/column';
import Card from '../../shared/components/card/card';
import Row from '../../shared/components/layout/row';
import GoogleAdsenseAd from '../../shared/components/google/google-adsense-ad';
import * as ReactMarkdown from 'react-markdown/with-html';
import { Link } from 'react-router-dom';
import Request from '../../shared/classes/communication/request';
import { floatInElements } from '../../shared/scripts/animations/float-in/float-in';
import { generateNavigation, generatePathNavigation } from './documents';
import NavSection from './nav-section';

import './document.css';

const DEFAULT_MARKDOWN = 'This page doesn\'t have any content.';

const ADVERTISEMENT_IDS = {
    HEADER: 'header',
    NAVIGATION: 'navigation',
    FOOTER: 'footer'
}

const WISE_REFERRAL_AD_URL = 'https://link.culleystudios.com/cs-wise-referral';
const WISE_REFERRAL_AD_URL_RAW = 'https://link.culleystudios.com/cs-wise-referral'

const REFERRAL_AD_PLACEMENTS = [ADVERTISEMENT_IDS.HEADER, ADVERTISEMENT_IDS.NAVIGATION, ADVERTISEMENT_IDS.FOOTER];

const REFERRAL_LABELS = {}

const REFERRAL_REDIRECTS = {
    [WISE_REFERRAL_AD_URL_RAW]: WISE_REFERRAL_AD_URL,
}

const VERSION_REGEX = /^[0-9]{1,2}\.[0-9]{1,3}(\.[0-9]{1,3})?(\.[0-9]{1,4})?$/

export default class DocumentPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: undefined,
            navigation: props.navigation,
            rendered: props.rendered,
            setRendered: props.setRendered,
            metadata: {
                path: props.path,
                title: '',
                src: '',
                markdown: '',
                related: [],
                updated: undefined,
                created: undefined,
            },
        }
        this.getMetadata(props.path);
    }

    getMetadata = (path) => {
        const errorHandler = (error) => {
            console.error(error);
        }

        new Request(`https://api.culleystudios.com/api/v1/document?path=${path}`, 15000, (data) => {
            if (!this.state.rendered)
                setTimeout(() => this.state.setRendered(true), 1000);

            floatInElements();
            const response = JSON.parse(data.target.response);
            const metadata = { ...response, markdown: response.markdown && response.markdown.length ? response.markdown : DEFAULT_MARKDOWN }
            this.setState({ ...this.state, metadata });
        }, errorHandler, errorHandler).getJSON();
    }

    generateBreadcrumbs = () => {
        let breadcrumbs = [];
        window.location.pathname.substring(1).split('/').forEach((part, index, parts) => {
            let path = '/' + parts.slice(0, index + 1).join('/');

            breadcrumbs.push(
                <Link to={path} key={path} active="secondary" className="btn btn-no-background btn-primary-hover">
                    {part}
                </Link>
                );

            if (index < parts.length - 1)
                breadcrumbs.push(<span key={index}>/</span>);
        });
        return breadcrumbs;
    }

    generatePages = (navigation, currentPath = undefined, search = undefined, renderCount = 5) => {
        return this.addNested(navigation, '/docs', undefined, false, `/docs${currentPath}`, (search ? search.toLowerCase().split(' ') : []), renderCount);
    }

    getAdvertisementFields = (advertisementId) => REFERRAL_AD_PLACEMENTS.includes(advertisementId) ? {
        label: REFERRAL_LABELS[advertisementId] ?? 'Referral',
        backgroundClass: 'wise-referral-container',
    } : {
        label: 'Advertisement',
        backgroundClass: 'bg-tertiary',
    }

    addNested = (obj, path, parentTitle = undefined, parentMatch = false, currentPath, search = [], renderCount = 5, depth = 0) => {
        const title = obj.pageTitle ? 
            (parentTitle && parentMatch ? String(obj.pageTitle).replace(`${parentTitle} - `, '') : obj.pageTitle) :
            undefined;

        const toPriority = (key1, key2) => {
            const keys = [key1, key2];
            if (keys.some(key => !VERSION_REGEX.test(key))) return 0;

            const partsArray = keys.map(key => key.split('\.'));
            const maxParts = Math.max(...partsArray.map(parts => parts.length))

            const [priority1, priority2] = partsArray.map(parts => {
                for (let i = parts.length; i < maxParts; i++) {
                    parts.push('0')
                }
                return Number(parts.map(part => part.padStart(4, '0')).join(''))
            })

            return priority2 - priority1
        }
        
        const keys = Object.keys(obj).filter(key => key !== 'pageTitle')
            .sort((key1, key2) => {
                const priority = toPriority(key1, key2)
                if (priority !== 0) {
                    return priority
                }
                return `${path}/${key1}` !== currentPath && key1.toLowerCase() > key2.toLowerCase() ? 1 : -1
            })

        parentMatch = (parentMatch || !search.length || (title && search.every(s => title.toLowerCase().includes(s))));

        const nested = keys.map(key => this.addNested(obj[key], `${path}/${key}`, title, parentMatch, currentPath, search, renderCount, depth + 1));

        return title && parentMatch ? (
            <NavSection
                key={path}
                renderCount={depth < 2 ? Number.MAX_SAFE_INTEGER : Math.max(renderCount, 5)}
                renderIncrement={5}
                path={path}
                title={title}
                parentMatch={parentMatch}
                nested={nested}
            />
        ) : nested;
    }

    render = () => {
        const title = this.state.metadata.title ? 'Culley Studios | ' + this.state.metadata.title : 'Culley Studios';
        const links = this.generateBreadcrumbs();

        const related = (
            <div className="mx-lg">
                {this.state.metadata.related.length ? this.generatePages(generateNavigation(this.state.metadata.related), this.props.path, undefined, 10) :
                    this.generatePages(generatePathNavigation(this.props.pages, this.state.metadata.path), this.props.path, undefined, 10)}
            </div>
        )

        const handleRedirect = (event) => {
            event.preventDefault();
            window.open(REFERRAL_REDIRECTS[event.currentTarget.href] ?? event.currentTarget.href, "_blank");
        }

        const pages = <div className="mx-lg">
            {this.state.navigation ?
                this.generatePages(this.state.navigation, undefined, this.state.search) :
                <div className="mx-md my-lg w-7"><Skeleton count={7}/></div>}
        </div>;

        const breadcrumbs = links.length <= 1 ? undefined : (
            <Row className="flex-mb-wrap w-10 gap-md">
                <Row className="flex-start flex-wrap flex-a-items-center bg-tertiary r-sm w-10">
                    {links}
                </Row>
            </Row>
        );
        const header = (
            <div>
                <h2 className="mx-md">{this.state.metadata.title ? this.state.metadata.title : <div className="w-5"><Skeleton /></div>}</h2>
                <hr className="primary w-10"/>
            </div>
        );

        const headerAdFields = this.getAdvertisementFields(ADVERTISEMENT_IDS.HEADER);
        const headerAd = REFERRAL_AD_PLACEMENTS.includes(ADVERTISEMENT_IDS.HEADER) ? (
            <a className='d-block w-10' href={WISE_REFERRAL_AD_URL_RAW} target="_blank" rel="noopener noreferrer" onClick={handleRedirect}>
                <div className="m-auto px-sm flex">
                    <img className="h-10" src="https://wise-creative.prf.hn/source/camref:1011l3CNzC/creativeref:1011l103169" alt="Wise - A cheaper way to make international business payments" />
                </div>
            </a>
        ) : (
            <GoogleAdsenseAd className="m-auto py-sm">
                <ins className="adsbygoogle"
                    style={{display: "inline-block", width: "728px", height: "90px"}}
                    data-ad-client="ca-pub-4548126687571070"
                    data-ad-slot="9951334107"
                    data-full-width-responsive="true"></ins>
            </GoogleAdsenseAd>
        );

        const footerAdFields = this.getAdvertisementFields(ADVERTISEMENT_IDS.FOOTER);
        const footerAd = REFERRAL_AD_PLACEMENTS.includes(ADVERTISEMENT_IDS.FOOTER) ? (
            <a className='d-block w-10' href={WISE_REFERRAL_AD_URL} target="_blank" rel="noopener noreferrer" onClick={handleRedirect}>
                <div className="px-sm flex">
                    <img className="h-10" src="https://wise-creative.prf.hn/source/camref:1011l3CNzC/creativeref:1011l103162" alt="Wise - Real fast, real fair, get the mid-market exchange rate and fair fees" />
                </div>
            </a>
        ) : (
            <GoogleAdsenseAd className="m-auto p-sm">
                <ins className="adsbygoogle"
                    style={{display: "block" }}
                    data-ad-client="ca-pub-4548126687571070"
                    data-ad-slot="1253866251"
                    data-ad-format="horizontal"
                    data-full-width-responsive="true"></ins>
            </GoogleAdsenseAd>
        );
        
        const navigationAdFields = this.getAdvertisementFields(ADVERTISEMENT_IDS.NAVIGATION);
        const navigationAd = REFERRAL_AD_PLACEMENTS.includes(ADVERTISEMENT_IDS.NAVIGATION) ? (
            <a className='d-block w-10' href={WISE_REFERRAL_AD_URL_RAW} target="_blank" rel="noopener noreferrer" onClick={handleRedirect}>
                <div className="px-sm flex">
                    <img className="w-10" src="https://wise-creative.prf.hn/source/camref:1011l3CNzC/creativeref:1011l103168" alt="Wise - Max ease, min fees, the smarter way to send money internationally" />
                </div>
            </a>
        ) : (
            <GoogleAdsenseAd className="m-auto p-sm">
                <ins className="adsbygoogle"
                    style={{display: "block" }}
                    data-ad-client="ca-pub-4548126687571070"
                    data-ad-slot="4655271518"
                    data-ad-format="rectangle"
                    data-full-width-responsive="true"></ins>
            </GoogleAdsenseAd>
        );

        return (
            <div>
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                <div className="mx-lg">
                    <Column className="flex-a-items-center w-max m-auto overflow-hidden">
                        <Row className="flex-mb-wrap w-10 hide-ad-mb gap-md">
                            <Row className={`flex-start flex-wrap flex-a-items-center ${headerAdFields.backgroundClass} r-sm w-10`}>
                                {headerAd}
                            </Row>
                        </Row>
                        <br className="d-mb-none"/>
                        {breadcrumbs}
                        <br/>
                        <Row className={`flex-mb-wrap w-10 gap-md ${this.props.rendered ? '' : 'float-in'}`}>
                            <Column className="flex-start w-7 w-mb-10">
                                <Card className="bg-secondary"
                                    headerClass="bg-tertiary"
                                    header={header}
                                    bodyClass="document-container"
                                    body={
                                        <div className="mx-md">
                                            {
                                                this.state.metadata.title || this.state.metadata.markdown ?
                                                    <ReactMarkdown
                                                        source={this.state.metadata.markdown}
                                                        escapeHtml={false}
                                                    /> :
                                                    <div className="mx-md my-lg w-7"><Skeleton count={7}/></div>
                                            }
                                        </div>
                                    }
                                    footerClass="bg-tertiary"
                                    footer={
                                        <Row className="flex-end p-md">
                                            {
                                                this.state.metadata.updated ?
                                                    <span className="o-7">{`Last updated: ${new Date(this.state.metadata.updated).toLocaleString()}`}</span> :
                                                    <div className="mx-md w-3"><Skeleton /></div>
                                            }
                                        </Row>
                                    }>
                                </Card>
                                <br/>
                                <Card className="bg-secondary"
                                    body={
                                        <div className={footerAdFields.backgroundClass}>
                                            { footerAd }
                                        </div>
                                    }
                                    footerClass="bg-tertiary"
                                    footer={
                                        <Column>
                                            <hr className="tertiary m-none w-10"/>
                                            <Row className="m-sm text-center">
                                                <span className="d-inline">{footerAdFields.label}</span>
                                            </Row>
                                        </Column>
                                    }>
                                </Card>
                                <br className="d-mb-none"/>
                            </Column>
                            <Column className="flex-start w-3 w-mb-10">
                                {
                                    related &&
                                        <Card className="bg-secondary"
                                            headerClass="bg-tertiary"
                                            header={
                                                <div>
                                                    <h2 className="mx-md">Related</h2>
                                                    <hr className="secondary w-10"/>
                                                </div>
                                            }
                                            bodyClass="pages-nav-container"
                                            body={related}>
                                        </Card>
                                }
                                { Object.keys(related).length ? <br/> : undefined }
                                <Card className="bg-secondary"
                                    headerClass="bg-tertiary"
                                    header={
                                        <div>
                                            <Row>
                                                <Column>
                                                    <h2 className="mx-md">Pages</h2>
                                                </Column>
                                                <Column className="m-sm w-10">
                                                    <form className="form-group" onSubmit={(e) => e.preventDefault()}>
                                                        <input type="text" className="form-input bg-primary" placeholder="Search for pages..." onChange={(event) => {
                                                            this.setState({...this.state, search: event.target.value})}
                                                        }/>
                                                    </form>
                                                </Column>
                                            </Row>
                                            <hr className="secondary w-10"/>
                                        </div>
                                    }
                                    bodyClass="pages-nav-container"
                                    body={pages}>
                                </Card>
                                <br/>
                                <Card className="bg-secondary"
                                    body={
                                        <div className={navigationAdFields.backgroundClass}>
                                            { navigationAd }
                                        </div>
                                    }
                                    footerClass="bg-tertiary"
                                    footer={
                                        <Column>
                                            <hr className="tertiary m-none w-10"/>
                                            <Row className="m-sm text-center">
                                                <span className="d-inline">{navigationAdFields.label}</span>
                                            </Row>
                                        </Column>
                                    }>
                                </Card>
                                <br/>
                            </Column>
                        </Row>
                    </Column>
                </div>
            </div>
        );
    }
}