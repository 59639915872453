import React, { Component } from 'react';
import Card from '../../card/card';
import './highlight-card.css';

class HighlightCard extends Component {

    componentHeight;
    windowWidth;
    resizing = false;
    height;
    margin;

    constructor(props) {
        super(props);
        this.height = this.props.height;
        this.state = {
            heightStyle: {}
        };
        this.setHeight = this.setHeight.bind(this);
        this.updateOnResize = this.updateOnResize.bind(this);
    }

    componentDidMount() {
        this.componentHeight = this.getHeightWithMargin(this.refs.container.clientHeight);
        this.setHeight(this.height);
        this.windowWidth = window.innerWidth;
        window.addEventListener("resize", this.updateOnResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateOnResize);
    }

    setHeight(height) {
    	if (this.resizing) {
    		return;
    	}
    
        this.setState(() => ({
            heightStyle: {
                height: this.getHeightWithoutMargin(height)
            }
        }));
    }

    getHeightWithMargin(height) {
        height = height.toString().replace('px', '');
        const margin = getComputedStyle(document.documentElement).getPropertyValue('--lg-margin').replace('px', '');
        return parseInt(height) + (margin * 2) + 'px';
    }

    getHeightWithoutMargin(height) {
        height = height.toString().replace('px', '');
        const margin = getComputedStyle(document.documentElement).getPropertyValue('--lg-margin').replace('px', '');
        return height - (margin * 2) + 'px';
    }
    
    getWindowDifference() {
    	return this.windowWidth - window.innerWidth;
    }

    updateOnResize() {
        const difference = this.getWindowDifference();

        if (difference > -5 && difference < 5) {
            return;
        }
        
        this.resizing = true;
        
        setTimeout(() => {
        	const comparison = difference - this.getWindowDifference();
        
        	if (comparison > 5 || comparison < -5) {
        		return;
        	}
        
	        this.windowWidth = window.innerWidth;
	
	        this.setState(() => ({
	            heightStyle: {}
	        }));
	
			this.resizing = false;
	        this.componentHeight = this.getHeightWithMargin(this.refs.container.clientHeight);
	        this.setHeight(this.height);
        
        }, 200);
    }

    render = () => {
        const title = this.props.title;
        const color = this.props.color || 'primary';
        const float = this.props.float || 'right';
        const children = this.props.children;

        return(
            <div ref="container" className={'highlight-card-container float-mb-' + float} style={this.state.heightStyle}>
                <Card className="highlight-card-overlay"
                    header={
                        <div className="m-sm">
                            <h2>{title}</h2>
                            <hr className={'w-9 w-mb-10 ' + color} />
                        </div>
                    }
                    body={<div className="m-sm">{children}</div>}
                ></Card>
            </div>
        );
    }
}

export default HighlightCard;