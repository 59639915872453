import React from 'react';
import { Link } from 'react-router-dom';
import * as Theme from '../../shared/scripts/theme';
import ThemeImage from '../../shared/components/image/theme-image';
import Row from '../../shared/components/layout/row';
import Column from '../../shared/components/layout/column';

const PageFooter = () => {
	const year = new Date().getUTCFullYear();
	return (
		<footer>
			<Row className="bg-tertiary">
				<Row className="flex w-10 flex-a-items-center flex-mb-column w-max mx-md">
					<Column className="m-md flex-g-5 flex-o-mb-last text-mb-center">
						<span>Ⓒ Culley Studios 2017-{year}. All rights reserved.</span>
					</Column>
					<Column>
						<Row className="flex-a-items-center">
							<Link to="/connect" className="btn btn-no-background btn-tertiary-hover">Contact</Link>
							<Link to="/privacy" className="btn btn-no-background btn-tertiary-hover">Privacy Policy</Link>
							<button className="btn btn-no-background d-mb-none" onClick={Theme.toggleColorTheme}>
								<ThemeImage alt="Toggle theme" className="btn-img-icon" images={{ 
									'dark': { 
										'src': '/images/icons/sun.svg',
									},
									'light': {
										'src': '/images/icons/moon.svg'
									}}}>
								</ThemeImage>
							</button>
						</Row>
					</Column>
				</Row>
			</Row>
		</footer>
	);
}

export default PageFooter;