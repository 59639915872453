import React from 'react'
import Row from '../shared/components/layout/row';
import Card from '../shared/components/card/card';
import Column from '../shared/components/layout/column';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => (
    <div>
        <Row className="mx-lg">
            <Column className="flex-a-items-center w-10">
                <Card className="w-10 w-max float-in"
                    headerClass="bg-tertiary"
                    header={
                        <div>
                            <h2 className="mx-md">Privacy Policy</h2>
                            <hr className="primary w-10 m-none"/>
                        </div>
                    }
                    bodyClass="bg-secondary"
                    body={
                        <div className="p-lg">
                            <p>
                                By using any applications, or services provided by Culley Studios or one of our a partners you are agreeing to the following Privacy Policy, in addition to any other Privacy Policies in place for specific applications and services.
                            </p>
                            <ol>
                                <li>The use of "We", "Us", and "Our" on this page refers to our company (Culley Studios).</li>
                                <li>The use of "Our Service" and "Our Services" on this page refers to our applications, and all other services we provide.</li>
                                <li>The use of "You" and "Your" refers to all visitors and users.</li>
                            </ol>
                            <p>
                                If you do not agree with this policy please discontinue use of all of our applications, and/or services.
                            </p>
                            <hr className="primary w-10"/>
                            <h3>What information do we collect?</h3>
                            <p>
                                We collect various information while you are using our services. The information we collect from you may include your e-mail address, and ip address of your device connecting to our service. While making any purchases we may also collect additional personal information including your name, address, phone number, and payment details. We collect your information to not only personalize your experience but also improve our services.
                            </p>
                            <h3>How do we protect your information?</h3>
                            <p>
                                We implement a number of security measures to ensure that all of our visitors data is kept safe and confidential. Information we collect may be stored and/or processed on computers located in any country we do business. Special situations may require disclosure of your data.
                            </p>
                            <h3>Cookies</h3>
                            <p>
                                Our service does use cookies to store information about our visitors preferences, record user-specific information on which pages the user access or visit, customize webpage content based on visitors browser type or other information that the visitor sends via their browser. Third party vendors, including Google, use cookies to serve ads based on a user's prior visits to your website. Google's use of the DoubleClick cookie enables it and its partners to serve ads to your users based on their visit to your sites and/or other sites on the Internet.
                            </p>
                            <p>
                                If you wish to disable cookies, you may do so through your individual browser options. More detailed information about cookie management with specific web browsers can be found at the browsers' respective websites.
                            </p>
                            <h3>Do we disclose information to outside parties?</h3>
                            <p>
                                We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or to protect our or others rights, property, or safety.
                            </p>
                            <p>
                                If you have any questions or concerns about our privacy policies, please feel free to <Link to="connect" className="text-primary">contact us</Link>.
                            </p>
                        </div>
                    }
                    footerClass="bg-tertiary"
                    footer={
                        <Row className="flex-end p-md">
                            <span className="o-7">Last updated: 6/15/2020</span>
                        </Row>
                    }
                />
                <br/>
            </Column>
        </Row>
    </div>
)

export default PrivacyPolicy;